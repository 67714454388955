$primary: #96c121;
$secondary: #1d252c;

.page {
    box-sizing: border-box;
    width: min(100%, 600px);
    height: min(100vh, 100%);
    padding: 2em;

    margin: 0 auto;

    background: $primary;

    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.16), 0 10px 10px -5px rgba(0, 0, 0, 0.16);

    h1,
    h2,
    p {
        text-align: center;
        color: white;
    }

    > div:first-of-type {
        display: flex;
        justify-content: center;

        margin-bottom: 2em;

        h2 {
            font-size: 3.5em;
            margin: 0.125em 0 0 0.5em;
        }
    }
}

.flex {
    width: 100%;
    display: flex;
}

.block {
    width: 100%;
    display: block;
}

.textRed {
    color: rgb(239, 68, 68) !important;
}

.copyright {
    margin-top: 4em;
}


@media (max-width: 36.125em) {
    .page {
        > div:first-of-type {
            display: block;

            > div {
                display: flex !important;
                justify-content: center;
            }

            h2 {
                margin: 0.25em 0 0;
            }
        }
    }
}