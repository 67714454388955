$primary: #96c121;
$secondary: #1d252c;
.inputGroup {
    width: 100%;
    display: flex;
    justify-content: space-between;

    margin: 0.5em 0;

    background-color: white;
    border-radius: 1.5em;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.16), 0 10px 10px -5px rgba(0, 0, 0, 0.16);
}

.inputLabel {

    text-align: left;
    padding: 0.5em 1em;

    span {
        color: rgb(239, 68, 68);
    }
}

.inputField,
.selectField {
    width: 60%;

    padding: 0.75em 1em;

    border-radius: 1.5em;
    border: none;
    outline: none;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.16), 0 10px 10px -5px rgba(0, 0, 0, 0.32);
    border: 2px solid transparent;

    &:hover,
    &:active {
        border: 2px solid $secondary;
    }

    &:focus {
        border: 2px solid $primary;
    }
}

.selectField {
    margin: 0;      
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;

    background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    radial-gradient(#ddd 70%, transparent 72%);
    background-position:
        calc(100% - 20px) calc(1em + 2px),
        calc(100% - 15px) calc(1em + 2px),
        calc(100% - 0.85em) .6em;
    background-size:
        5px 5px,
        5px 5px,
        1.5em 1.5em;
    background-repeat: no-repeat;
}

.submit {
    float:right;
    background: white;
    margin: 0;
    cursor: pointer;

    border: 2px solid white;

    &:hover,
    &:active {
        color: white !important;
        background: $secondary;
    }

    &:focus {
        border: 2px solid $secondary;
    }
}


.error {
    background: rgb(239, 68, 68);
    border-radius: 1.5em;
    padding: 0.75rem;
    text-align: center;
    color: white;
    font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace;
}


@media (max-width: 36.125em) {
    .inputGroup {
        display:block;
    }

    .inputLabel {
        display: block;
        width: 100%;
        margin: 1em 0 0.25em;
        text-align: center;
    }

    .inputField,
    .selectField {
        width: 100%;

        box-shadow: 0 -5px 25px -5px rgba(0, 0, 0, 0.16), 0 10px 10px -5px rgba(0, 0, 0, 0.32);
    }

    .submit {
        display: block;
        margin-top: 1em;
        margin-bottom: 1em;
        padding: 1em 0;
    }
}